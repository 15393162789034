import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, COMPONENTS, Dialog, Loading,
} from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import { useServices } from 'v2/hooks/useServices';
import { stringifyAndPrettifyJson } from '../../../../../bi/utils/json';
import { alertDev } from '../../../../../utils/alert';

import { DECISIONBUTTONS } from '../../../../../bi/constants/decisionButtons';
import { TRIPBUTTONS } from '../../../../../bi/constants/trips';

import styles from '../styles/dialog.module.css';

const LABELS = {
  DOCUMENT_IS_EMPTY: 'Документ пустой',
  DATA: 'Данные внесены',
  SUCCESS: 'SUCCESS',
};

const ERRORS = {
  403: 'У вас нет прав на редактирование документов в закрытом периоде',
  400: 'Произошла ошибка при редактировании наполнения документа (полученный json имеет некорректный формат)',
};

class EditDocumentDialog extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    packageId: PropTypes.number.isRequired,
    documentId: PropTypes.number.isRequired,
    onOpenErrorTransaction: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      data: '',
      waitingResponse: {
        getData: true,
        flagResError: false,
        saveData: false,
      },
    };
  }

  componentDidMount() {
    const { companyService, documentId } = this.props;

    companyService.documents.getDocument([documentId])
      .then((res) => this.setState({
        data: stringifyAndPrettifyJson(res),
        waitingResponse: {
          ...this.state.waitingResponse,
          getData: false,
        },
      }))
      .catch((e) => {
        alertDev(e, 'companyService', 'getDocument');
        this.setState({
          waitingResponse: {
            ...this.state.waitingResponse,
            flagResError: true,
          },
        });
      });
  }

  handleChangeData = (event) => this.setState({ data: event.target.value });

  handleConfirm = () => {
    const {
      companyService, onClose, packageId, onOpenErrorTransaction,
    } = this.props;
    const { data } = this.state;
    const { notificationsService: { send, levels } } = useServices(['Notifications']);

    const confirm = () => companyService.documents.updateDocument(packageId, data)
      .then(() => {
        send({
          level: levels.get(LABELS.SUCCESS),
          message: LABELS.DATA,
        });
      })
      .catch((res) => {
        return onOpenErrorTransaction(ERRORS[String(res.statusCode)]);
      })
      .finally(onClose);

    this.setState({ waitingResponse: { ...this.state.waitingResponse, saveData: true } }, confirm);
  };

  closeErrorForm = () => {
    const { onClose } = this.props;

    this.setState({
      waitingResponse: {
        ...this.state.waitingResponse,
        flagResError: false,
        getData: false,
      },
    });
    onClose();
  };

  renderLoading = () => {
    const { waitingResponse: { flagResError } } = this.state;

    if (flagResError) {
      return (
        <Dialog onClick={ this.closeErrorForm } width={ COMPONENTS.DIALOG.MIDDLE }>
          <div className={ styles.dialog }>
            <p>{ LABELS.DOCUMENT_IS_EMPTY }</p>
            <div className={ `${styles.row} ${styles.right}` }>
              <Button
                label={ TRIPBUTTONS.CLOSE }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ this.closeErrorForm }
              />
            </div>
          </div>
        </Dialog>
      );
    }

    return (
      <Dialog onClick={ this.props.onClose } width='800px'>
        <div className={ styles.loading }>
          <Loading size='large' />
        </div>
      </Dialog>
    );
  };

  render() {
    const { onClose } = this.props;
    const { data, waitingResponse } = this.state;

    return waitingResponse.getData
      ? this.renderLoading()
      : (
        <Dialog onClick={ onClose } width='800px'>
          <div className={ styles.dialog }>
            <div className={ styles.row }>
              <textarea
                className={ styles.text }
                value={ data }
                onChange={ this.handleChangeData }
                rows='10'
              />
            </div>
            <div className={ styles.actions }>
              <DecisionButtons
                waitingResponse={ waitingResponse.saveData }
                onSave={ this.handleConfirm }
                onCancel={ onClose }
                labelSave={ DECISIONBUTTONS.LABELS.CONFIRM }
              />
            </div>
          </div>
        </Dialog>
      );
  }
}

export { EditDocumentDialog };
